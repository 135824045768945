<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="indigo"
      icon="mdi-state-machine"
      title="Listado Estados Expedientes"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="expedientes"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />

            <v-dialog
              v-model="dialogOrden"
              persistent
              scrollable
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  class="mx-2"
                  v-on="on"
                >
                  Ordenar Estados
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Ordenar Estado Expediente</span>
                </v-card-title>
                <v-card-text>
                  <draggable
                    v-model="expedientesOrder"
                    class="list-group py2"
                    tag="ul"
                    v-bind="dragOptions"
                    :move="onMove"
                    @start="isDragging=true"
                    @end="isDragging=false"
                  >
                    <transition-group
                      type="transition"
                      :name="'flip-list'"
                    >
                      <li
                        v-for="element in expedientesOrder"
                        :key="element.order"
                        class="list-group-item py-3 px-2 mx-3"
                      >
                        <!-- <i
                          :class="element.fixed? 'mdi mdi-anchor' : 'mdi mdi-pin'"
                          aria-hidden="true"
                          @click="element.fixed=! element.fixed"
                        /> -->
                        {{ element.name }}
                        <!-- <span class="badge" v-slot:badge>{{ element.order }}</span> -->
                      </li>
                    </transition-group>
                  </draggable>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeOrden"
                  >
                    {{ $t("close") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="saveOrden"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialog"
              persistent
              scrollable
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  class="m-2"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-text-field
                            v-model="editedItem.nombre"
                            :rules="[v => !!v || 'El nombre es requerido']"
                            label="Nombre"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="12"
                        >
                          <v-text-field
                            v-model="editedItem.descripcion"
                            label="Descripción"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            v-model="editedItem.estado"
                            :rules="[v => !!v || 'El estado es requerido']"
                            :items="estados"
                            item-text="nombre"
                            item-value="id"
                            label="Estado"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-color-picker
                            v-model="editedItem.color"
                            class="ma-2"
                            hide-inputs
                            mode="hexa"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    {{ $t("close") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <dashboard-audit-trail
              :visible="dialogAudit"
              :logs="logs"
              :titulo="titleLog"
              @close="dialogAudit = false"
            />
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon
            small
            class="mr-2"
            color="success"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
          <v-icon
            small
            color="error"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="info"
            @click.stop="showLogs(item)"
          >
            mdi-history
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import draggable from 'vuedraggable'
  import MaestrosEstadoExpedienteApi from '@/services/api/MaestrosEstadoExpediente'
  export default {
    name: 'MaestrosEstadoExpediente',

    components: {
      draggable,
      DashboardAuditTrail: () => import('@/views/dashboard/component/AuditTrail'),
    },

    data () {
      return {
        dialogAudit: false,
        titleLog: '',
        logs: {},
        valid: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        dialog: false,
        dialogOrden: false,
        expedientes: [],
        expedientesOrder: [],
        editable: true,
        isDragging: false,
        delayedDragging: false,
        editedIndex: -1,
        lazy: false,
        estados: [
          {
            id: 'A',
            nombre: 'Activo',
          },
          {
            id: 'I',
            nombre: 'Inactivo',
          },
        ],
        headers: [
          {
            text: 'Nombre',
            align: 'left',
            sortable: false,
            value: 'nombre',
          },
          {
            text: 'Descripción',
            value: 'descripcion',
          },
          {
            text: 'Estado',
            value: 'estado',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          nombre: '',
          descripcion: '',
          estado: '',
          orden: 0,
          color: '',
        },
        defaultItem: {
          id: '',
          nombre: '',
          descripcion: '',
          estado: 'A',
          orden: 0,
          color: '',
        },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Estado Expediente' : 'Modificar Estado Expediente'
      },
      dragOptions () {
        return {
          animation: 0,
          group: 'description',
          disabled: !this.editable,
          ghostClass: 'ghost',
        }
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogOrden (val) {
        val || this.closeOrden()
      },
      isDragging (newValue) {
        if (newValue) {
          this.delayedDragging = true
          return
        }
        this.$nextTick(() => {
          this.delayedDragging = false
        })
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchEstadoExpediente () {
        MaestrosEstadoExpedienteApi.getAllEstadoExpediente()
          .then(expediente => {
            this.expedientes = expediente
            this.order(expediente)
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchEstadoExpediente()
      },
      order (test) {
        this.expedientesOrder = test.map((name, index) => {
          var rObj = {}
          rObj.id = name.id
          rObj.name = name.nombre
          rObj.order = name.orden
          rObj.fixed = false
          return rObj
          // return { name: name.nombre, order: name.orden, fixed: false }
        })
      },

      editItem (item) {
        this.editedIndex = this.expedientes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      ordenEstado () {
        this.dialogOrden = true
      },

      saveOrden () {
        MaestrosEstadoExpedienteApi.ordenarEstados(this.expedientesOrder)
          .then(response => {
            this.text = 'Registro actualizado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
            this.fetchEstadoExpediente()
          })
          .catch(error => {
            console.log(error)
            this.text = 'Lo sentimos, no pueden ser actualizados los estados en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      deleteItem (item) {
        const index = this.expedientes.indexOf(item)
        if (confirm('Realmente desea eliminar este registro?')) {
          MaestrosEstadoExpedienteApi.deleteEstadoExpediente(item.id)
            .then(response => {
              this.expedientes.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      closeOrden () {
        this.dialogOrden = false
      },

      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            const updEstadoExpediente = this.editedItem
            const indexItem = this.editedIndex
            MaestrosEstadoExpedienteApi.updateEstadoExpediente(this.editedItem.id, this.editedItem)
              .then(response => {
                Object.assign(this.expedientes[indexItem], updEstadoExpediente)
                this.text = 'Registro modificado correctamente'
                this.snackbar = true
                this.colorSnak = 'success'
                this.editedIndex = -1
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser modificado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
                this.editedIndex = -1
              })
          } else {
            const addEstadoExpediente = this.editedItem
            MaestrosEstadoExpedienteApi.addEstadoExpediente(this.editedItem)
              .then(response => {
                addEstadoExpediente.id = response.id
                this.expedientes.push(addEstadoExpediente)
                this.order(this.expedientes)
                this.text = 'Registro creado correctamente'
                this.colorSnak = 'success'
                this.snackbar = true
                this.editedIndex = -1
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser creado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
          this.close()
        }
      },

      showLogs (item) {
        this.titleLog = item.nombre
        MaestrosEstadoExpedienteApi.getLogsExpediente(item.id)
          .then(logs => {
            this.logs = logs
            this.dialogAudit = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'Error al cargar los datos de auditoría.'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      onMove ({ relatedContext, draggedContext }) {
        const relatedElement = relatedContext.element
        const draggedElement = draggedContext.element
        return (
          (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
        )
      },
    },
  }
</script>
<style>
.flip-list-move {
  transition: transform 0.5s !important;
}
.no-move {
  transition: transform 0s !important;
}
.ghost {
  opacity: 0.5 !important;
  background: #c8ebfb !important;
}
.list-group {
  min-height: 20px !important;
}
.list-group-item {
  cursor: move !important;
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  font-size: 14px;
}
.list-group-item i {
  cursor: pointer !important;
}
</style>
